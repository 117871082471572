import React from "react"
import PageHeading from "../components/PageHeadings"

const S17DPage = () => {
  return (
    <div>
      <PageHeading
        pageHeading="Kodiak Products"
        description="cray direct trade  readymade narwhal YOLO cornhole Kickstarter kitsch wayfarers artisan synth Vice seitan polaroid food truck Banksy fingerstache Thundercats art party cred chia next level Pinterest slow-carb before  Life disrupt"
      />
    </div>
  )
}

export default S17DPage
